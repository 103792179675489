 #ai-article-writer 
.ql-container {
  border: 1px solid #FF981A !important;
  border-radius: 8px !important;
  width: 100% !important;
  
}

#ai-article-writer  .ql-editor {
    height: 400px;
    width: 100% !important;
    overflow-y: auto;
  }

  #ai-article-writer .ql-snow h1 {
    font-size: 18px !important;
    font-weight: bold;
    word-spacing: normal;
  }
  
  #ai-article-writer .ql-snow h2 {
    font-size: 16px !important;
    font-weight: normal;
    line-height: 1.3;
    word-spacing: normal;
  }
  
  #ai-article-writer .ql-snow h3 {
    font-size: 14px !important;
    font-weight: normal;
    line-height: 1.4;
    word-spacing: normal;
  }
  
  #ai-article-writer .ql-snow h4 {
    font-size: 14px !important;
    font-weight: bold;
    line-height: 1.4;
    word-spacing: normal;
  }
  
  
  #ai-article-writer .ql-snow h5 {
    font-size: 12px !important;
    font-weight: bold;
    line-height: 1.4;
    word-spacing: normal;
  }
  
  #ai-article-writer .ql-snow p {
    font-size: 10px !important;
    font-weight: normal;
    line-height: 1.5;
    color: #333;
  }
  
