.side-div {
    background-color: white; /* White background for the sidebar */
    color: #333; /* Darker text color for readability */
    min-width: 300px;
    height: 650px;
    overflow: visible;
    padding: 10px 0;
    border-right: 1px solid #ddd; 
    display: flex;
    flex-direction: column; /* Stack child elements vertically */
    justify-content: space-between;
}
.folders-container {
    overflow-y: auto; 
    padding: 10px 0; 
    height: 100%;
}
.side-div-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    margin-bottom: 10px;
}

.sidebar-title {
    margin: 0;
    color: #333;
    font-size: 20px;
}

.folder-title {
    background: none;
    border: none;
    color: #333;
    padding: 8px 10px;
    width: 100%;
    text-align: left;
    font-size: 18px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: background-color 0.3s; 
    
}

.folder-title:hover, .folder-title:focus {
    background-color: rgba(255, 213, 128, 0.2); 
    outline: none;
}


.folder {
    border-bottom: 1px solid #ddd; 
}



.folder.open .prompts {
    display: block;
}

.side-prompt-item {
    color: #555; 
    cursor: pointer;
    font-size: 18px;
    background-color: transparent;
    border: none;
    padding: 10px 10px;
    text-align: left;
    width: 100%;
    box-sizing: border-box;
    transition: background-color 0.3s;
    display: flex;
    justify-content: space-between;
}

.side-prompt-item:hover {
    background-color: rgba(255, 213, 128, 0.2);
    outline: none;
}

.side-prompt-item:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(255, 213, 128, 0.5);
}
.icon {
    margin-right: 10px;
}

.folder-text {
    display: flex;
    align-items: center;
}

.icon {
    margin-right: 10px;
}



.ai-chat-msg-box {
    margin-right: 10px;
}

.hover-info {
    position: absolute;
    top: 0;
    left: 300px; 
    width: 300px; 
    height: 650px; 
    background-color: rgba(255,255,255,0.9); 
    z-index: 100; 
    padding: 20px 0px;
    box-shadow: 0px 0px 8px rgba(0,0,0,0.2);
    overflow-y: auto;
}

